<template>
  <div>
    <b-tabs
      content-class="pb-lg-5 p-2 pt-lg-5 bg-white block-search-content"
      active-nav-item-class="font-weight-bold text-primary-light"
      nav-class="nav-search"
      @activate-tab="eventActiveTab"
      >
      <b-tab :title="$t('School')" :active="activeTab['school']">
        <b-form @submit.stop.prevent="onSubmitSeachSchool">
          <b-row class="px-2">
            <b-col lg="10">
              <b-row class="pt-2 pt-lg-0">
              <b-col lg="4" class="pb-3">
                <label for="input-destination" class="text-primary-dark font-weight-bold">{{ $t('Destinations') }}</label>
                <v-select ref="search-destination-school" class="form-v-selects"
                  :placeholder="$t('Province or States')"
                  label="text"
                  :value="selectedRegion"
                  v-model="selectedRegion"
                  :filterable="false"
                  :options="regions"
                  @search="searchRegion">
                  <template #search="{attributes, events}">
                    <input
                      class="vs__search 11"
                      v-bind="attributes"
                      v-on="events"
                      @click="setReadOnly"
                    />
                  </template>
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      {{ $t('No results found for') }} <em>{{ search }}</em>.
                    </template>
                    <em style="opacity: 0.5;" v-else><small>{{ $t('Typing to search for a Province or States') }}</small></em>
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.text }}
                      </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.text }}
                    </div>
                  </template>
                </v-select>
              </b-col>
              <b-col lg="4" class="pb-3">
                <label for="input-event-name" class="text-primary-dark font-weight-bold">{{ $t('School name/ID') }}</label>
                <b-form-input
                  id="input-event-name"
                  v-model="form.school_name"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  :placeholder="$t('Keyword')"
                ></b-form-input>
              </b-col>
              <b-col lg="4" class="">
                <label for="input-designation" class="text-primary-dark font-weight-bold">{{ $t('Designation') }}</label>
                  <b-form-select id="input-designation" v-model="form.type_option" :options="school_type_options" value-field="id" text-field="name">
                    <template #first>
                      <b-form-select-option :value="null">{{ $t('Select option') }}</b-form-select-option>
                    </template>
                  </b-form-select>
              </b-col>
              </b-row>
            </b-col>
            <b-col lg="2" sm="8" class="d-flex align-items-center px-3 mt-4 mb-4 pl-lg-2 col-8 offset-2 offset-md-0 offset-md-2 offset-lg-0">
              <b-button pill variant="primary" type="submit" block class="font-weight-bold btn-danger-light mt-2"> <font-awesome-icon :icon="['fas', 'search']" class="mr-3" />{{ $t('Search') }}</b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-tab>
      <b-tab :title="$t('Teacher')" :active="activeTab['teacher']">
        <b-form @submit.stop.prevent="onSubmitTeacher">
          <b-row class="px-2">
            <b-col lg="10">
              <b-row class="pt-2 pt-lg-0">
              <b-col lg="4" class="pb-3">
                <label for="input-destination" class="text-primary-dark font-weight-bold">{{ $t('Destinations') }}</label>
                <v-select  ref="search-destination-teacher" class="form-v-selects"
                  :placeholder="$t('Province or States')"
                  label="text"
                  :value="selectedRegion"
                  v-model="selectedRegion"
                  :filterable="false"
                  :options="regions"
                  @search="searchRegion">
                  <template #search="{attributes, events}">
                    <input
                      class="vs__search 11"
                      v-bind="attributes"
                      v-on="events"
                      @click="setReadOnly"
                    />
                  </template>
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      {{ $t('No results found for') }} <em>{{ search }}</em>.
                    </template>
                    <em style="opacity: 0.5;" v-else><small>{{ $t('Typing to search for a Province or States') }}</small></em>
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.text }}
                      </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.text }}
                    </div>
                  </template>
                </v-select>
              </b-col>
              <b-col lg="4" class="pb-3">
                <label for="input-school-name" class="text-primary-dark font-weight-bold">{{ $t('Teacher name/ID') }}</label>
                <b-form-input
                  id="input-school-name"
                  v-model="form.teacher_name"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  :placeholder="$t('Keyword')"
                ></b-form-input>
              </b-col>
              <b-col lg="4" class="">
                <label for="input-designation-teacher" class="text-primary-dark font-weight-bold">{{ $t('Designation') }}</label>
                  <b-form-select id="input-designation-teacher" v-model="form.type_option" :options="teacher_apply_type_options" value-field="id" text-field="name">
                    <template #first>
                      <b-form-select-option :value="null">{{ $t('Select option') }}</b-form-select-option>
                    </template>
                  </b-form-select>
              </b-col>
              </b-row>
            </b-col>
            <b-col lg="2" sm="8" class="d-flex align-items-center px-4 mt-4 mb-4 pl-lg-2 col-8 offset-2 offset-md-0 offset-md-2 offset-lg-0">
              <b-button pill variant="primary" type="submit" block class="font-weight-bold btn-danger-light mt-2"> <font-awesome-icon :icon="['fas', 'search']" class="mr-3" />{{ $t('Search') }}</b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-tab>
      <b-tab :title="$t('Training & Event')" :active="activeTab['event']">
        <b-form @submit.stop.prevent="onSubmitSeachEvent">
          <b-row class="px-2">
            <b-col lg="10">
              <b-row class="pt-2 pt-lg-0">
                <b-col lg="4" class="pb-3">
                  <label for="input-destination-event" class="text-primary-dark font-weight-bold">{{ $t('Destinations') }}</label>
                  <v-select id="input-destination-event" class="form-v-selects" :placeholder="$t('Province or States')" :value="selectedRegion"  v-model="selectedRegion" label="text" :filterable="false" :options="regions" @search="searchRegion">
                  <template #search="{attributes, events}">
                    <input
                      class="vs__search 11"
                      v-bind="attributes"
                      v-on="events"
                      @click="setReadOnly"
                    />
                  </template>
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      {{ $t('No results found for') }} <em>{{ search }}</em>.
                    </template>
                    <em style="opacity: 0.5;" v-else>{{ $t('Typing to search for a Province or States') }}</em>
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.text }}
                      </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.text }}
                    </div>
                  </template>
                </v-select>
                </b-col>
                <b-col lg="4" class="pb-3">
                  <label for="input-traning" class="text-primary-dark font-weight-bold">{{ $t('Training & Event') }}</label>
                  <b-form-input
                    id="input-traning"
                    v-model="form.traning"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    :placeholder="$t('Keyword')"
                  ></b-form-input>
                </b-col>
                <b-col lg="2" class="pl-3 pr-3 pl-sm-3 pr-sm-3 pl-lg-1 pr-lg-1 pl-xl-2 pr-xl-2 pb-3">
                  <label for="input-start-date" class="text-primary-dark font-weight-bold">{{ $t('Start date') }}</label>
                    <month-picker-input class="mb-2 d-block" @change="selectStartDate" :lang="currentLanguage" :min-date="minDate" :default-month="startMonthDate" :default-year="startYearDate" :input-pre-filled="true" v-model="startDate"></month-picker-input>
                </b-col>
                <b-col lg="2" class="pl-3 pr-3 pl-sm-3 pr-sm-3 pl-lg-1 pr-lg-1 pl-xl-2 pr-xl-2">
                  <label for="input-end-date" class="text-primary-dark font-weight-bold">{{ $t('End date') }}</label>
                  <month-picker-input class="mb-2 d-block w-100" @change="selectEndDate" :lang="currentLanguage" :default-month="endMonthDate" :default-year="endYearDate" :input-pre-filled="true" :min-date="minDate"></month-picker-input>
                </b-col>
              </b-row>
            </b-col>
            <b-col lg="2" sm="8" class="d-flex align-items-center px-3 mt-4 mb-4 pl-lg-2 col-8 offset-2 offset-md-0 offset-md-2 offset-lg-0">
              <b-button pill variant="primary" type="submit" block class="font-weight-bold btn-danger-light mt-2"> <font-awesome-icon :icon="['fas', 'search']" class="mr-3" />{{ $t('Search') }}</b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Api from '../../api/api'
import 'vue-select/dist/vue-select.css'
import { MonthPickerInput } from 'vue-month-picker'

const currentDate = new Date()
const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, 0)
const date = new Date()
const y = date.getFullYear()
const m = date.getMonth()
const firstDayOfMonth = new Date(y, m, 1)

export default {
  name: 'BlockSeach',
  components: {
    MonthPickerInput
  },
  data () {
    return {
      form: {
        search_type: 'school',
        school_name: '',
        teacher_name: '',
        traning: '',
        start_date: this.$options.filters.dateFormat(firstDayOfMonth, 'YYYY-MM-DD'),
        e_date: endDate,
        region: {
          value: null,
          text: null
        },
        type_option: null
      },
      activeTab: {
        school: false,
        teacher: false,
        event: false
      },
      style_of_yogas: [],
      teacher_apply_type_options: [],
      school_type_options: [],
      selectedRegion: null,
      regions: [],
      queryType: '',
      startDate: {
        month: this.$options.filters.dateFormat(firstDayOfMonth, 'MM'),
        year: this.$options.filters.dateFormat(firstDayOfMonth, 'YYYY')
      },
      endDate: {
        month: this.$options.filters.dateFormat(endDate, 'MM'),
        year: this.$options.filters.dateFormat(endDate, 'YYYY')
      },
      minDate: firstDayOfMonth,
      startMonthDate: parseInt(currentDate.getMonth() + 1),
      startYearDate: parseInt(currentDate.getFullYear()),
      endMonthDate: parseInt(endDate.getMonth() + 1),
      endYearDate: parseInt(endDate.getFullYear())
    }
  },
  computed: {
    currentLanguage () {
      return this.$store.getters.currentLanguage
    }
  },
  mounted () {
    this.getFormdata()
    this.form = { ...this.form, ...this.$store.getters.formSearchDirectory }
    if (this.form.region.value === null) {
      this.selectedRegion = null
    } else {
      this.selectedRegion = this.form.region
    }
    this.queryType = this.$route.query.type
    if (this.queryType) {
      this.form.search_type = this.queryType
    }
    if (this.form.search_type === 'event') {
      this.activeTab.event = true
    } else if (this.form.search_type === 'teacher') {
      this.activeTab.teacher = true
    } else {
      this.activeTab.school = true
    }
  },
  created () {
    this.selectedRegion = null
  },
  methods: {
    selectStartDate (date) {
      this.form.start_date = this.$options.filters.dateFormat(date.from, 'YYYY-MM-DD')
    },
    selectEndDate (date) {
      const endDate = new Date(date.from.getFullYear(), date.from.getMonth() + 1, 0)
      this.form.e_date = this.$options.filters.dateFormat(endDate, 'YYYY-MM-DD')
    },
    onContextStartDate () {
      this.form.start_date = this.$options.filters.dateFormat(new Date(this.form.start_date), 'YYYY-MM-DD')
      document.getElementById('input-start-date__value_').innerHTML = this.form.start_date
    },
    onContextEndDate () {
      this.form.e_date = this.$options.filters.dateFormat(new Date(this.form.e_date), 'YYYY-MM-DD')
      document.getElementById('input-end-date__value_').innerHTML = this.form.e_date
    },
    eventActiveTab (e) {
      if (e === 0) {
        this.form.search_type = 'school'
        this.onSelectTab()
      } else if (e === 1) {
        this.form.search_type = 'teacher'
        this.onSelectTab()
      } else {
        this.form.search_type = 'event'
        this.onSelectTab()
      }
    },
    setReadOnly (el) {
      setTimeout(() => {
        el.target.removeAttribute('readonly')
      }, 80)
    },
    onSelectTab () {
      this.$emit('selectTab', this.form)
    },
    onSubmitTeacher (event) {
      if (this.selectedRegion !== null) {
        this.form.region = this.selectedRegion
      } else {
        this.form.region = { value: null, text: null }
      }
      this.form.search_type = 'teacher'
      this.$emit('submit', this.form)
    },
    onSubmitSeachSchool (event) {
      if (this.selectedRegion !== null) {
        this.form.region = this.selectedRegion
      } else {
        this.form.region = { value: null, text: null }
      }
      this.$emit('submit', this.form)
    },
    onSubmitSeachEvent (event) {
      if (this.selectedRegion !== null) {
        this.form.region = this.selectedRegion
      } else {
        this.form.region = { value: null, text: null }
      }
      this.form.search_type = 'event'
      this.$emit('submit', this.form)
    },
    getFormdata () {
      Api.shoolFormData().then(response => {
        if (response.data) {
          this.school_type_options = response.data.school_types
        }
      })
      Api.formDataTeacher().then(response => {
        if (response.data) {
          this.teacher_apply_type_options = response.data.teacher_type_apply
        }
      })
    },
    searchRegion (searchText, loading) {
      this.searchText = searchText
      if (this.searchText) {
        this.regions = []
        loading(true)
        Api.searchRegion({ q: this.searchText }).then(response => {
          if (response.data.results) {
            response.data.results.forEach(region => {
              const url = region.url.split('/')
              this.regions.push({ value: url[url.length - 2], text: region.display_name })
            })
          }
          loading(false)
        }).catch(() => {
          loading(false)
        })
      }
    }
  }
}
</script>
<style >
  .block-search-content {
    min-width: 250px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0px 20px 40px #0000000D;
  }
  .tab-search-item-nav {
    background-color: #2c3e50 !important;
    color: white !important;
  }
  .nav-tabs {
    border: none;
  }
  .nav-search .nav-item {
    margin-bottom: -1px;
    background: #2c3e50;
    border-top-left-radius: 5px;
    margin-right: 3px;
    border-top-right-radius: 5px;
    font-size: 1.2vw;
    font-size: 0.9rem;
  }
  .nav-search .nav-item .nav-link {
    color: white;
    font-weight: 700;
    opacity: 1;
  }
  .block-search-content .btn .b-icon.bi, .block-search-content .nav
  -link .b-icon.bi, .block-search-content .dropdown-toggle .b-icon.bi, .block-search-content .dropdown-item .b-icon.bi, .block-search-content .input-group-text .b-icon.bi{
    font-size: 110%;
  }
  .block-search-content .b-form-btn-label-control.form-control > label{
    font-size:0.9rem;
  }
  .vs__dropdown, .form-v-selects, .vs__dropdown-toggle {
    height: 38px;
  }
  .vs__dropdown-toggle {
    border: 1px solid #ced4da
  }
  .vs__selected {
    height: 28px;
  }
  .vs__search::placeholder {
    color: #76787e
  }
  .vs__clear {
    margin-top: -3px;
  }
  .d-center {
    display: flex;
    align-items: center;
  }

  .v-select .dropdown li {
    border-bottom: 1px solid rgba(112, 128, 144, 0.1);
  }

  .v-select .dropdown li:last-child {
    border-bottom: none;
  }

  .v-select .dropdown li a {
    padding: 10px 20px;
    width: 100%;
    font-size: 1.25em;
    color: #3c3c3c;
  }

  .v-select .dropdown-menu .active > a {
    color: #fff;
  }
  .vs__dropdown-menu > li {
    padding: 5px !important;
    padding-left: 7px !important;
    border-bottom: 1px solid #f6f8fa;
  }
  .month-picker__container {
    z-index: 100;
    top: 4.4em !important;
  }
  .month-picker-input-container {
    width: 100% !important;
    top: 4.4em !important;
    position: initial !important;
  }
  .month-picker-input {
    padding: 6px 12px 6px 12px !important;
    width: 100%;
    font-size: 16px !important;
  }
  .month-picker {
    background-color: white;
  }
</style>
