<template>
  <b-row v-if="results && results.results.length > 0" class="pt-2">
    <b-col lg="3" sm="6" cols="6" md="4"  class="box-item mt-3 px-2" v-for="item in results.results" :key="item.id">
      <div class="item item-popular rounded-lg">
        <div class="text-center bg-white" style="posion:relative">
          <b-link :to="linkProfile(item)">
            <b-img v-if="searchType == 'event'" blank-color="#fff"  class="border-0 rounded-top img-item" :src="item.image_small ? item.image_small : PUBLIC_PATH+'img/icons/android-chrome-512x512.png'"></b-img>
            <b-img v-if="searchType == 'school'" blank-color="#fff"  class="border-0 rounded-top img-item" :src="item.doc_logo || item.doc_cover ? (item.doc_logo || item.doc_cover) : PUBLIC_PATH+'img/icons/android-chrome-512x512.png'"></b-img>
            <b-img v-if="searchType == 'teacher'" blank-color="#fff"  class="border-0 rounded-top img-item" :src="item.doc_avatar || item.doc_cover ? (item.doc_avatar || item.doc_cover) : PUBLIC_PATH+'img/icons/android-chrome-512x512.png'"></b-img>
          </b-link>
        </div>
        <div class="p-3">
          <p v-if="searchType == 'school'" class="text-danger-light text-13 text-uppercase">{{ item.region_name }}</p>
          <p v-if="searchType == 'event'" class="text-danger-light text-13 text-uppercase">{{ item.region_name +', '+ item.region_country_name }}</p>
          <p v-if="searchType == 'teacher'" class="text-danger-light text-13 text-uppercase">{{ item.region_name }}</p>
          <h5 v-if="searchType == 'school'" class="text-primary-dark font-weight-600 text-15">
            <b-link :to="{ name: 'schoolProfileDetail', params: { schoolId: item.id } }">{{ item.school_name }}</b-link>
          </h5>
          <h5 v-if="searchType == 'event'" class="text-primary-dark font-weight-600 text-15">
            <b-link :to="{ name: 'eventDetail', params: { eventId: item.id } }">{{ item.subject }}</b-link>
            <p class="mt-2 text-11">{{ item.start_time_short }} {{ new Date(item.start_date) |  dateFormat('DD MMM YYYY', dateFormatConfig) }} - {{ item.end_time_short }} {{ new Date(item.end_date) |  dateFormat('DD MMM YYYY', dateFormatConfig) }}</p>
          </h5>
          <h5 v-if="searchType == 'teacher'" class="text-primary-dark font-weight-600 text-15">
            <b-link :to="{ name: 'teacherProfileDetail', params: { teacherId: item.id } }">{{ item.name }}</b-link>
          </h5>
          <small v-if="searchType == 'school' || searchType == 'teacher'" class="text-gray-light">
            <span class="pr-1">{{ $t('Member Since') }} :</span><span class="text-primary-dark">{{ new Date(item.member_since) | dateFormat('DD MMMM YYYY', dateFormatConfig) }}</span>
          </small>
          <div v-if="searchType == 'event'"><small class="text-gray-light">{{ item.school_name || item.teacher_name }}</small></div>
          <!-- <br> -->
          <b-button v-if="searchType == 'school'" pill variant="link" :to="{ name: 'schoolProfileDetail', params: { schoolId: item.id } }" class="font-weight-bold text-decoration-none text-primary-light pl-0 mt-2 d-inline-block text-14">{{ $t('Read more') }} <font-awesome-icon :icon="['fas', 'angle-right']"/></b-button>
          <b-button v-if="searchType == 'event'" pill variant="link" :to="{ name: 'eventDetail', params: { eventId: item.id } }" class="font-weight-bold text-primary-light text-decoration-none pl-0 mt-2 text-14">{{ $t('Read more') }} <font-awesome-icon :icon="['fas', 'angle-right']"/></b-button>
          <b-button v-if="searchType == 'teacher'" pill variant="link" :to="{ name: 'teacherProfileDetail', params: { teacherId: item.id } }" class="font-weight-bold text-primary-light text-decoration-none pl-0 mt-2 text-14">{{ $t('Read more') }} <font-awesome-icon :icon="['fas', 'angle-right']"/></b-button>
        </div>
      </div>
    </b-col>
  </b-row>
  <b-row v-else class="text-center justify-content-center py-5">
    <div class="search-notfound-container">
      <div class="search-notfound-image-box">
        <b-img  blank-color="#fff" class="" style="height:100%;" :src="require('../../assets/images/icon/yoga_search_not_found.png')"></b-img>
      </div>
      <h5 class="d-block pt-3">{{ $t('No results') }}</h5>
    </div>
  </b-row>
</template>

<script>
export default {
  name: 'ResultSearchItem',
  props: {
    results: Object,
    searchType: String
  },
  methods: {
    linkProfile (item) {
      var linkTo = null
      switch (this.searchType) {
        case 'event':
          linkTo = { name: 'eventDetail', params: { eventId: item.id } }
          break
        case 'teacher':
          linkTo = { name: 'teacherProfileDetail', params: { teacherId: item.id } }
          break
        case 'school':
          linkTo = { name: 'schoolProfileDetail', params: { schoolId: item.id } }
          break
        default:
          linkTo = { name: 'schoolProfileDetail', params: { schoolId: item.id } }
      }
      return linkTo
    }
  }
}
</script>
<style scoped>
  .item-recommended-teacher {
    background-color: #fff;
  }
  .item-img {
    background-color: #fdfdfd;
  }
  .btn-read-more {
    background-color: #3B6098 !important;
    border-color: #3B6098 !important;
  }
  .img-item {
    /*object-fit: none;*/
    object-position: center;
    /*height: 200px;*/
    width: 100%;
  }
</style>
